<template>
  <div class="profile scroll-container" v-if="user">
    <!-- <v-container class="d-flex fill-height"> -->
    <CategoryTitle :category="category" />
    <p v-if="isUpdate">
      CartAmica: <strong>{{ user.CardCode }}</strong>
    </p>

    <v-form
      ref="personDataRef"
      v-model="personData"
      :disabled="response.status == 1 && !firstStep && !isUpdate"
    >
      <h3>Dati anagrafici:</h3>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="user.Name"
            label="Nome *"
            :id="'id_' + Math.random()"
            :rules="firstNameRules"
            required
            readonly
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="user.Surname"
            label="Cognome *"
            :id="'id_' + Math.random()"
            :rules="lastNameRules"
            required
            outlined
            readonly
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" class="pb-0">
          <v-select
            v-model="selectedBirthCountry"
            label="Nazione di nascita *"
            :id="'id_' + Math.random()"
            :rules="requiredRules"
            :items="countryList"
            item-text="Name"
            item-value="Name"
            return-object
            required
            outlined
            dense
            @change="selectBirthCountry"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-select
            v-if="isBirthInItaly"
            v-model="selectedBirthProvince"
            label="Provincia *"
            :id="'id_' + Math.random()"
            :items="provinceList"
            item-text="Nome"
            item-value="Sigla"
            return-object
            :rules="requiredRules"
            required
            outlined
            dense
            autocomplete="birthProvince"
            :disabled="!user.BirthCountry"
            @change="selectBirthProvince"
          ></v-select>
          <v-text-field
            v-else
            v-model="user.BirthForeignProv"
            label="Provincia di nascita"
            :id="'id_' + Math.random()"
            :rules="birthProvinceRules"
            @blur="$refs.personDataRef.validate()"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            v-if="isBirthInItaly"
            v-model="selectedBirthCity"
            label="Comune di nascita *"
            :id="'id_' + Math.random()"
            :search-input.sync="birthCity"
            :items="birthCityList"
            item-text="Nome"
            item-value="Nome"
            return-object
            :rules="requiredRules"
            required
            outlined
            dense
            auto-select-first
            hide-no-data
            :disabled="!user.BirthProv && isBirthInItaly"
            clearable
            autocomplete="BirthCity"
            @change="selectBirthCity"
            @keyup.tab="selectBirthCity"
          ></v-autocomplete>
          <v-text-field
            v-else
            v-model="user.BirthCity"
            :id="'id_' + Math.random()"
            :rules="birthCityRules"
            label="Comune di nascita *"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="user.BirthZipCode"
            v-if="!isBirthInItaly"
            :id="'id_' + Math.random()"
            :rules="birthZipRules"
            @blur="$refs.personDataRef.validate()"
            label="Zip Code"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="birthDate"
            label="Data di nascita *"
            readonly
            disabled
            v-mask="'##/##/####'"
            outlined
            dense
          ></v-text-field>
          <!-- <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthDate"
                label="Data di nascita *"
                :id="'id_' + Math.random()"
                :rules="requiredRules"
                v-mask="'##/##/####'"
                required
                readonly
                outlined
                dense
                @change="generateFiscalCode"
              >
                <v-icon slot="append" v-bind="attrs" v-on="on">
                  mdi-calendar
                </v-icon>
              </v-text-field>
            </template>
            <v-date-picker
              locale="it-IT"
              ref="picker"
              v-model="user.BirthDate"
              :id="'id_' + Math.random()"
              :max="
                new Date()
                  | dayjs('subtract', 18, 'year')
                  | dayjs('format', 'YYYY-MM-DD')
              "
              min="1910-01-01"
              first-day-of-week="1"
              type="date"
              no-title
              @input="menu = false"
            ></v-date-picker>
          </v-menu> -->
        </v-col>

        <v-col cols="12" sm="6">
          <v-radio-group
            @change="generateFiscalCode"
            v-model="user.Gender"
            hide-details
            row
            required
            :rules="requiredRules"
          >
            <v-radio label="Maschio" value="M"></v-radio>
            <v-radio label="Femmina" value="F"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12" sm="6" class="pb-0">
          <v-text-field
            v-model="fiscalCode"
            label="Codice Fiscale"
            :id="'id_' + Math.random()"
            :rules="cfRules"
            :required="isBirthInItaly"
            :loading="loadingFiscalCode"
            @blur="$refs.personDataRef.validate()"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-form
      ref="resDataRef"
      v-if="!firstStep || isUpdate"
      v-model="resData"
      :disabled="response.status == 1"
    >
      <v-row>
        <v-col cols="12" class="mb-4" v-if="!isUpdate">
          <h3>Sei gia' in possesso di una CartAmica? *</h3>

          <!-- @change="generateFiscalCode" -->
          <v-radio-group
            v-model="hasCard"
            hide-details
            row
            required
            :rules="requiredRules"
            :readonly="fidelityCardReadOnly"
            @change="changeHasCard"
          >
            <v-radio label="Si" value="true"></v-radio>
            <v-radio label="No" value="false"></v-radio>
          </v-radio-group>

          <v-text-field
            class="mt-3 fidelity-card-input"
            v-if="hasCard === 'true'"
            v-model="user.CardCode"
            label="Codice carta *"
            v-mask="'#############'"
            :id="'id_' + Math.random()"
            :rules="fidelityCardRules"
            :readonly="fidelityCardReadOnly"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <h3>Dati residenza:</h3>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-select
            label="Nazione di residenza *"
            v-model="selectedCountry"
            :id="'id_' + Math.random()"
            :rules="requiredRules"
            required
            outlined
            dense
            :items="countryList"
            return-object
            item-text="Name"
            item-value="Name"
            @change="selectCountry"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-select
            v-if="isResInItaly"
            label="Provincia di residenza *"
            v-model="selectedProvince"
            item-text="Nome"
            item-value="Sigla"
            :id="'id_' + Math.random()"
            :rules="requiredRules"
            required
            outlined
            dense
            return-object
            :items="provinceList"
            @change="selectProvince"
          ></v-select>
          <!-- @change="fetchResCityList" -->
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-autocomplete
            v-if="isResInItaly"
            v-model="selectedCity"
            :search-input.sync="resCity"
            :items="cityList"
            return-object
            item-text="Nome"
            item-value="Nome"
            :id="'id_' + Math.random()"
            :disabled="!selectedProvince"
            :rules="requiredRules"
            auto-select-first
            clearable
            required
            outlined
            dense
            hide-no-data
            autocomplete="new_password"
            label="Località *"
            @change="selectCity"
            @v-on:keyup.tab="selectCity"
          ></v-autocomplete>

          <v-text-field
            v-else
            v-model="user.ResCity"
            label="Comune di residenza *"
            outlined
            dense
            clearable
            :id="'id_' + Math.random()"
            :rules="resCityRules"
            :type="'text'"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            v-if="isResInItaly"
            v-model="selectedAddress"
            :id="'id_' + Math.random()"
            :search-input.sync="resAddress"
            :items="addressList"
            item-text="Nome"
            item-value="Nome"
            :disabled="!selectedCity"
            :rules="requiredRules"
            auto-select-first
            clearable
            required
            outlined
            dense
            hide-no-data
            return-object
            autocomplete="new_password"
            label="Indirizzo di residenza *"
            @change="selectAddress"
            @v-on:keyup.tab="selectAddress"
          ></v-autocomplete>
          <v-text-field
            v-else
            v-model="user.ResAddress"
            label="Indirizzo di residenza *"
            outlined
            dense
            clearable
            :id="'id_' + Math.random()"
            :rules="requiredRules"
            :type="'text'"
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="2">
          <v-text-field
            v-model="user.ResCivicNumber"
            label="Civico *"
            outlined
            dense
            clearable
            :id="'id_' + Math.random()"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="6" md="4">
          <v-select
            v-if="isResInItaly"
            v-model="user.ResCAP"
            label="Cap *"
            :rules="requiredRules"
            required
            outlined
            dense
            return-object
            :id="'id_' + Math.random()"
            :items="postalcodeList"
          ></v-select>
          <v-text-field
            v-else
            v-model="user.ResCAP"
            label="CAP *"
            outlined
            dense
            clearable
            :id="'id_' + Math.random()"
            :rules="CAPRules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="user.IdPDVPref"
            label="Punto vendita preferito"
            outlined
            dense
            :id="'id_' + Math.random()"
            :items="pdvList"
            item-text="PDV"
            item-value="CodicePDV"
          ></v-select>
        </v-col>
      </v-row>
      <div v-if="isUpdate">
        <h3>Credenziali d'accesso:</h3>
        <br />
        <h3>Modifica Email:</h3>
        <v-form ref="updateEmailRef" v-model="emailData">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.Email"
                label="Email *"
                type="email"
                :id="'id_' + Math.random()"
                disabled
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.newEmail"
                label="Nuova Email *"
                type="email"
                :id="'id_' + Math.random()"
                :rules="newEmailRules"
                autocomplete="off"
                required
                outlined
                dense
                @blur="$refs.updateEmailRef.validate()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="emailConfirm"
                label="Conferma nuova Email *"
                :id="'id_' + Math.random()"
                :rules="newEmailConfirmRules"
                autocomplete="off"
                required
                outlined
                dense
                @blur="$refs.updateEmailRef.validate()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-row-reverse">
              <v-btn
                @click.prevent="handleUpdateEmailSubmit"
                color="primary"
                depressed
                :loading="loadingUpdateEmail"
                :disabled="disabledUpdateMail"
                large
                >{{ $t("profile.updateEmail") }}</v-btn
              >
            </v-col>
          </v-row>
          <ResponseMessage
            :response="responseUpdateEmail"
            v-if="responseUpdateEmail"
          />
        </v-form>
        <v-divider></v-divider>
        <br />
        <h3>Modifica password:</h3>
        <v-form ref="updatePasswordRef" v-model="passwordData">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.CurrentPassword"
                label="Password attuale *"
                :id="'id_' + Math.random()"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :rules="currentPasswordRules"
                autocomplete="off"
                required
                outlined
                dense
                clearable
                @blur="$refs.updatePasswordRef.validate()"
                @click:append.prevent="toggleShowPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.Password"
                label="Nuova Password *"
                :id="'id_' + Math.random()"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :rules="newPasswordRules"
                autocomplete="off"
                required
                outlined
                dense
                clearable
                @blur="$refs.updatePasswordRef.validate()"
                @click:append.prevent="toggleShowPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="passwordConfirm"
                label="Conferma Password *"
                :id="'id_' + Math.random()"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :rules="newPasswordConfirmRules"
                autocomplete="off"
                required
                outlined
                dense
                clearable
                @blur="$refs.updatePasswordRef.validate()"
                @click:append.prevent="toggleShowPassword"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-row-reverse">
              <v-btn
                @click.prevent="handleUpdatePasswordSubmit"
                color="primary"
                :loading="loadingUpdatePassword"
                depressed
                :disabled="disabledUpdatePassword"
                large
                >{{ $t("profile.updatePassword") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <ResponseMessage
          :response="responseUpdatePassword"
          v-if="responseUpdatePassword"
        />
        <v-dialog v-model="requestPasswordDialog" max-width="500px" persistent>
          <v-card>
            <v-card-title class="headline">Inserisci la password</v-card-title>
            <v-card-text>
              Per confermare il cambio email inserisci la password
              <v-text-field
                v-model="passwordConfirmDialog"
                type="password"
                label="Password"
                @keyup.enter="handleUpdateEmailSubmit(null)"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closePasswordRequestDialog"
                >Annulla</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="handleUpdateEmailSubmit(null)"
                >Conferma</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div
        class="d-flex privacy-section flex-column"
        v-if="!firstStep || isUpdate"
      >
        <h3 class="mt-2">
          1.
          <strong
            >DICHIARAZIONE DI ACCETTAZIONE DEL REGOLAMENTO CARTAMICA</strong
          >
          (Indispensabile per sottoscrivere CartAmica)
        </h3>

        <p>
          Dichiaro di aver letto e di accettare espressamente il regolamento
          CartAmica.
        </p>

        <v-checkbox
          color="primary"
          class="ma-0 mb-2 privacy-check"
          hide-details
          required
          v-model="user.ConsentRelease"
          label="Accetto *"
        >
        </v-checkbox>

        <h3 class="mt-2">
          2. <strong>MANIFESTAZIONE DI CONSENSO A FINI DI MARKETING</strong>
        </h3>
        <v-alert color="primary" class="white--text font-weight-bold mt-4"
          >ESPRIMI IL TUO CONSENSO PER RICEVERE IL MEGLIO DELLE NOSTRE OFFERTE
          IN ANTEPRIMA PER TE!
        </v-alert>

        <p>
          Presto il consenso al trattamento dei miei dati personali e
          identificativi da parte di Iperal Supermercati S.p.A. o di terzi
          delegati (postalizzazione) per l'invio anche mediante l'utilizzo di
          posta elettronica/sms di comunicazioni relative alle iniziative
          Iperal.
          <router-link
            :to="{
              name: 'Page',
              params: { pageName: 'privacy-policy' }
            }"
            class="no-underline"
          >
            Leggi tutti i dettagli.
          </router-link>
        </p>

        <v-row class="phone-section">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.Cell"
              label="Cellulare"
              outlined
              dense
              :rules="isMobilePhoneRules"
              v-mask="'##########'"
              masked="false"
              clearable
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.Tel"
              label="Telefono fisso"
              outlined
              dense
              :rules="isHomePhoneRules"
              v-mask="[
                '#######',
                '########',
                '#########',
                '##########',
                '###########'
              ]"
              masked="false"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <template>
          <v-radio-group v-model="user.ConsentMarketing" row>
            <v-radio color="primary" label="Accetto" :value="true"></v-radio>
            <v-radio
              color="primary"
              label="Non accetto"
              :value="false"
            ></v-radio>
          </v-radio-group>
        </template>

        <h3 class="mt-2">
          3.
          <strong
            >MANIFESTAZIONE DI CONSENSO A FINI DI PROFILAZIONE E
            STATISTICI</strong
          >
        </h3>

        <v-alert color="primary" class="white--text font-weight-bold mt-4"
          >ESPRIMI IL TUO CONSENSO PER PROPORTI SCONTI, COUPON, OFFERTE
          PERSONALIZZATE E PER CONSENTIRTI LA PARTECIPAZIONE A CONCORSI IN BASE
          ALLE TUE SCELTE DI ACQUISTO
        </v-alert>

        <p>
          Presto il consenso al trattamento dei miei dati da parte di Iperal
          Supermercati S.p.A. per essere inserito in campioni statistici di
          Clienti per la valutazione e il miglioramento della politica
          commerciale e per analisi delle mie abitudini di acquisto al fine di
          proporLe offerte mirate.
          <router-link
            :to="{ name: 'Page', params: { pageName: 'privacy-policy' } }"
            class="no-underline"
          >
            Leggi tutti i dettagli.
          </router-link>
        </p>

        <template>
          <v-radio-group v-model="user.ConsentStats" row>
            <v-radio color="primary" label="Accetto" :value="true"></v-radio>
            <v-radio
              color="primary"
              label="Non accetto"
              :value="false"
            ></v-radio>
          </v-radio-group>
        </template>
      </div>
    </v-form>
    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <RecaptchaDisclaimer @click="clicked" />
      </v-col>
    </v-row>

    <ResponseMessage :response="response" v-if="response.status" />
    <div class="row text-right">
      <div class="col-12">
        <template v-if="(!firstStep || isUpdate) && !response.status">
          <v-btn
            class="bg-white mr-2"
            color="primary"
            outlined
            to="/"
            large
            depressed
          >
            {{ $t("profile.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click.prevent="handleUserDataSubmit"
            large
            depressed
            :disabled="disabledUpdate"
            :loading="loadingUpdate"
          >
            {{ $t("profile.save") }}
          </v-btn>
        </template>
        <v-btn
          v-else-if="firstStep && !response.status && !isUpdate"
          class="primary"
          @click="goToSecondStep"
          large
          depressed
          :disabled="!personData"
          :loading="loadingSecondStep"
        >
          Continua
        </v-btn>
        <v-btn
          v-else
          @click="response = {}"
          large
          depressed
          outlined
          color="primary"
        >
          Riprova
        </v-btn>
      </div>
    </div>
    <div class="row text-right">
      <div class="col-12">
        <template>
          <v-btn
            large
            color="primary"
            outlined
            min-width="250"
            class="bg-white text-uppercase flex-start"
            @click="anonymizeAccount()"
            :loading="loadingAnonymizeAccount"
            >{{ $t("profile.anonymizeAccountBtn") }}
          </v-btn>
        </template>
      </div>
    </div>
    <v-spacer class="spacer-scroll"></v-spacer>
    <a v-if="$platform_is_cordova && isBetaTester" :href="developmentLink"
      >Apri versione di test
    </a>
  </div>
</template>
<style lang="scss">
.calendar {
  margin-top: -6px;
}
.fidelity-card-input {
  max-width: 300px;
}
.privacy-check label {
  font-size: 14px;
  color: $text-color !important;
  line-height: 1;
}
.profile {
  .v-col {
    padding-bottom: 0;
    .v-text-field__details {
      margin-bottom: 1px;
    }
  }
  .v-stepper {
    background: transparent;
    box-shadow: none;
    .v-stepper__header {
      box-shadow: none;
    }
    .v-stepper__step__step {
      height: 35px;
      min-width: 35px;
      width: 35px;
    }
  }
  .v-autocomplete {
    .v-input__append-inner {
      display: none;
    }
  }
  .v-input__slot {
    background-color: $white !important;
  }
  .v-input__prepend-outer {
    position: absolute;
    right: 0px;
    z-index: 2;
  }
  .v-input--radio-group {
    .v-input__slot {
      background-color: transparent !important;
    }
  }
  .privacy-section {
    margin-bottom: 30px;
    h3 {
      font-weight: initial;
    }
    a {
      text-decoration: underline;
    }
    .v-input__slot {
      background-color: transparent !important;
    }
    .phone-section {
      .v-input__slot {
        background-color: $white !important;
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
import IperalCustomService from "@/service/iperalCustomService";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";
import clickHandler from "~/mixins/clickHandler";
import {
  requiredValue,
  isMobilePhone,
  isHomePhone,
  isCAP,
  isPasswordMinimumLengthIperal,
  isCity,
  isIstatCode,
  isEmail,
  isEmailIperal,
  isNumber,
  isLength,
  minLength
} from "@/validator/validationRules";
// import SecurityService from "~/service/securityService";
import CategoryTitle from "@/components/category/CategoryTitle";
import ResponseMessage from "@/components/ResponseMessage";
import categoryMixins from "~/mixins/category";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import debounce from "lodash/debounce";
import UserService from "~/service/userService";
export default {
  name: "Profile",
  components: {
    CategoryTitle,
    ResponseMessage,
    RecaptchaDisclaimer
  },
  mixins: [categoryMixins, clickHandler],
  directives: { mask },
  computed: {
    developmentLink() {
      //da testare se device esiste veramente in vue.js
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return "/mobile/develop/" + platform + "/index.html";
    },
    disabledUpdate() {
      return !this.personData || !this.resData || !this.user.ConsentRelease;
    },
    disabledUpdateMail() {
      return !this.emailData;
    },
    disabledUpdatePassword() {
      return !this.passwordData;
    },
    disabledFirstStep() {
      return !this.personData;
    },
    isUpdate() {
      console.log(this.profileLevel);
      return this.profileLevel > 1;
    },
    isBirthInItaly() {
      return (
        this.selectedBirthCountry &&
        this.selectedBirthCountry.Code.toUpperCase() === "ITA"
      );
    },
    isResInItaly() {
      return (
        this.selectedCountry &&
        this.selectedCountry.Code.toUpperCase() === "ITA"
      );
    },
    ...mapGetters({
      profileLevel: "cart/profileLevel",
      sessionUser: "cart/getUser",
      isBetaTester: "cart/isBetaTester"
    }),
    birthDate: {
      get: function() {
        if (this.user.BirthDate) {
          return this.$dayjs(this.user.BirthDate).format("DD/MM/YYYY");
        } else {
          return null;
        }
      },
      set: function(value) {
        this.user.BirthDate = this.$dayjs(value).format("DD/MM/YYYY");
      }
    },
    fiscalCode: {
      get: function() {
        return this.user.FiscalCode;
      },
      set: function(value) {
        this.user.FiscalCode = value;
      }
    }
  },
  data() {
    return {
      response: {},
      responseUpdateEmail: {},
      responseUpdatePassword: {},
      menu: false,
      user: {
        BirthCountry: "ITALIA",
        ResCountry: "ITALIA",
        ConsentMarketing: false,
        ConsentStats: false
      },

      firstStep: true,
      personData: false,
      passwordData: false,
      emailData: false,
      resData: true,

      showPassword: false,
      passwordConfirm: "",
      emailConfirm: null,
      lazy: true,

      firstNameRules: [requiredValue("Digitare il nome")],
      lastNameRules: [requiredValue("Digitare il cognome")],
      birthCityRules: [requiredValue(), isCity()],
      resCityRules: [requiredValue(), isCity()],
      passwordRules: [requiredValue("Digitare la password")],
      newPasswordRules: [
        requiredValue("Digitare la nuova password"),
        isPasswordMinimumLengthIperal()
      ],
      newPasswordConfirmRules: [
        v => v === this.user.Password || "Le password non coincidono",
        isPasswordMinimumLengthIperal()
      ],
      currentPasswordRules: [requiredValue("Digitare la password attuale")],
      passwordConfirmRules: [
        requiredValue("Confermare la password"),
        v => v === this.user.Password || "Le password non coincidono"
      ],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      newEmailRules: [isEmailIperal()],
      newEmailConfirmRules: [
        v => v === this.user.newEmail || "Le email non coincidono"
      ],
      emailConfirmRules: [
        requiredValue("Confermare l'email"),
        v => v === this.user.Email || "Le email non coincidono"
      ],
      fidelityCardRules: [
        requiredValue(),
        isNumber("Deve contenere solo cifre"),
        isLength(13, "La CartAmica deve avere 13 cifre")
      ],
      requiredRules: [requiredValue()],
      birthProvinceRules: [
        v =>
          !!(v || this.user.BirthZipCode) ||
          "Provincia di nascita o ZIP Code obbligatori"
      ],
      birthZipRules: [
        v =>
          !!(v || this.user.BirthForeignProv) ||
          "Provincia di nascita o ZIP Code obbligatori",
        isNumber("Il campo deve contenere solo cifre"),
        minLength(5, "Deve avere almeno 5 cifre")
      ],
      istatCodeRules: [requiredValue("Digitare lo ZIP Code"), isIstatCode()],
      cfRules: [
        v => {
          if (
            !v &&
            !!this.selectedBirthCountry &&
            this.selectedBirthCountry.Code === "ITA"
          ) {
            return (
              /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/.test(
                v.toUpperCase()
              ) || "Formato non corretto"
            );
          } else if (v) {
            minLength(19, "Formato non corretto")(v);
          }
        }
      ],
      CAPRules: [requiredValue("Digitare il CAP"), isCAP()],
      isHomePhoneRules: [isHomePhone()],
      isMobilePhoneRules: [isMobilePhone()],
      countryList: [],
      birthCityList: [],
      selectedBirthCountry: null,
      selectedBirthProvince: null,
      selectedBirthCity: null,
      provinceList: [],
      toastedOption: {
        position: "bottom-center",
        duration: 3000,
        iconPack: "mdi",
        theme: "bubble",
        keepOnHover: true,
        action: {
          icon: "mdi-close",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        },
        bottom: true
      },
      cityList: [],
      addressList: [],
      postalcodeList: [],
      passwordConfirmDialog: null,
      loadingUpdatePassword: false,
      loadingUpdateEmail: false,
      selectedCountry: null,
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      pdvList: [],
      requestPasswordDialog: false,
      hasCard: null,
      loadingUpdate: false,
      loadingFiscalCode: false,
      loadingSecondStep: false,
      birthCity: [requiredValue(), isCity()],
      resCity: [requiredValue(), isCity()],
      resAddress: null,
      fidelityCardReadOnly: false,
      loadingAnonymizeAccount: false
      //userFidelityCard: null
    };
  },
  methods: {
    ...mapActions({
      loadCart: "cart/loadCart",
      logout: "cart/doLogout"
    }),
    closePasswordRequestDialog() {
      this.passwordConfirmDialog = "";
      this.requestPasswordDialog = false;
    },
    async goToSecondStep() {
      this.loadingSecondStep = true;
      // let birthDateISO = null;
      // if (this.user.BirthDate) {
      //   birthDateISO = this.$dayjs(this.user.BirthDate).format("YYYY-MM-DD");
      // }
      try {
        this.user = await IperalCustomService.checkUser(this.user);
      } catch (r) {
        this.response = r;
        return;
        //this.$router.push("/profile/profile.update-error");
      } finally {
        this.loadingSecondStep = false;
      }

      //this.userFidelityCard = this.user.CardCode;
      this.formatData();
      this.fetchDefaultResData();

      this.firstStep = false;
    },
    goToExternalLink(url) {
      window.open(url, "_blank");
    },
    async handleUpdatePasswordSubmit(e) {
      let _this = this;
      _this.responseUpdatePassword = {};
      _this.loadingUpdatePassword = true;
      try {
        e.preventDefault();
        if (
          _this.user.CurrentPassword == "" ||
          !_this.$refs.updatePasswordRef.validate()
        ) {
          console.error("validazione campi non completata");
          _this.loadingUpdatePassword = false;
          return;
        }
        _this.loadingUpdatePassword = true;
        //need to update password
        let response = await IperalCustomService.updatePasswordForUser(
          _this.user
        );
        if (response) {
          _this.responseUpdatePassword = response.response;
        }
        _this.loadingUpdatePassword = false;
        //logout and go to confirm
        await this.logout();
        this.$router.push({
          name: "PasswordUpdated"
        });
      } catch (r) {
        console.log(r);
        this.responseUpdatePassword = r;
        _this.loadingUpdatePassword = false;
      }
    },
    async handleUpdateEmailSubmit(e) {
      let _this = this;
      _this.responseUpdateEmail = {};
      try {
        if (e) {
          e.preventDefault();
        }
        if (
          _this.user.CurrentPassword == "" ||
          !_this.$refs.updateEmailRef.validate()
        ) {
          console.error("validazione campi non completata");
          _this.loadingUpdateEmail = false;
          return;
        }
        if (
          _this.passwordConfirmDialog == null ||
          _this.passwordConfirmDialog == ""
        ) {
          _this.requestPasswordDialog = true;
          return;
        } else {
          _this.requestPasswordDialog = false;
        }
        _this.loadingUpdateEmail = true;
        let response = await IperalCustomService.updateEmail(
          _this.user,
          _this.passwordConfirmDialog
        );
        _this.passwordConfirmDialog = "";
        if (response) {
          _this.responseUpdateEmail = response.response;
        }
        _this.user.Email = _this.user.newEmail;
        _this.loadingUpdateEmail = false;
        //logout and go to confirm
        await this.logout();
        this.$router.push({
          name: "EmailUpdated"
        });
        /*let res = await this.logout();
        if (res) {
          setTimeout(() => {
            this.$router.push({
              name: "EmailUpdated"
            });
          }, 200);
        } */
      } catch (r) {
        console.log(r);
        this.responseUpdateEmail = r;
        this.loadingUpdate = false;
        _this.loadingUpdateEmail = false;
        this.passwordConfirmDialog = "";
      }
    },
    async handleUserDataSubmit(e) {
      try {
        e.preventDefault();
        this.loadingUpdate = true;

        if (
          !this.$refs.personDataRef.validate() ||
          !this.$refs.resDataRef.validate()
        ) {
          this.loadingUpdate = false;
          return;
        }
        //corret date format
        this.user.BirthDate = this.$dayjs(this.user.BirthDate).format(
          "YYYY-MM-DD"
        );
        console.log(this.user);
        let user = await IperalCustomService.updateUser(this.user);
        if (user) {
          this.loadCart();
          if (this.profileLevel < 2) {
            this.$router.push({
              name: "ProfileConfirmed"
            });
          } /*else if (
            this.user.Email.toUpperCase() !=
            this.sessionUser.email.toUpperCase()
          ) {
            //this.logout(true);
            this.$router.push({
              name: "EmailUpdated"
            }); }*/ else {
            this.$router.push({
              name: "ProfileUpdated"
            });
          }
        }
      } catch (r) {
        console.log(r);
        this.response = r;
        //this.$router.push("/profile/profile.update-error");
      } finally {
        this.loadingUpdate = false;
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },

    formatData() {
      if (this.user.BirthCountry) {
        this.user.BirthCountry = this.capitalizeFirstLetter(
          this.user.BirthCountry
        );
      } else {
        this.user.BirthCountry = "Italia";
      }

      if (this.user.ResCountry) {
        this.user.ResCountry = this.capitalizeFirstLetter(this.user.ResCountry);
      } else {
        this.user.ResCountry = "Italia";
      }
      if (this.user.CardCode) {
        this.hasCard = "true";
        this.fidelityCardReadOnly = true;
      }
      if (
        this.user &&
        (this.user.ConsentMarketing === undefined ||
          this.user.ConsentMarketing === null)
      ) {
        this.user.ConsentMarketing = false;
      }

      if (
        this.user &&
        (this.user.ConsentStats === undefined ||
          this.user.ConsentStats === null)
      ) {
        this.user.ConsentStats = false;
      }
    },
    capitalizeFirstLetter(string) {
      return string && string.length
        ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
        : "";
    },

    async selectBirthCountry(country) {
      let _this = this;
      _this.user.BirthCountry = country.Name;
      _this.user.BirthProv = null;
      _this.selectedBirthProvince = null;
      _this.user.BirthCity = null;
      _this.selectedBirthCity = null;
    },
    async selectBirthProvince(province) {
      let _this = this;
      this.user.BirthProv = province.Sigla;
      _this.user.BirthCity = null;
      _this.selectedBirthCity = null;
      _this.birthCityList = [];
    },
    async selectBirthCity(city) {
      if (city) {
        this.user.BirthCity = city.Nome;
        //devo comporre l'istat code...
        this.generateFiscalCode();
        this.user.BirthIstatCode = await IperalCustomService.getIstatCode(
          this.selectedBirthProvince.Istat,
          city.Id
        );
      } else if (this.birthCityList.length > 0) {
        this.selectBirthCity(this.birthCityList[0]);
      }
    },
    selectCountry(country) {
      let _this = this;
      _this.user.ResCountry = country.Name;
      this.resetProvince();
    },
    async fetchProvinceList() {
      let _this = this;
      if (_this.user.ResCountry == "Italia" && _this.provinceList.length == 0) {
        _this.provinceList = await IperalCustomService.getPosteProvinceList();
      }
    },
    selectProvince(province) {
      if (province.Id) {
        //vm.selectedProvince = province;
        this.user.ResProv = province.Sigla;
        this.resetCity();
        this.resetAddress();
      }
    },
    resetProvince() {
      this.user.ResProv = null;
      this.selectedPorvince = null;
      this.resetCity();
      this.resetAddress();
    },
    async getCityList(filter, idProvince) {
      var cityList = await IperalCustomService.getPosteCityList(
        filter,
        idProvince
      );
      return cityList;
    },
    fetchBirthCityList: debounce(async function(val) {
      let _this = this;
      if (_this.selectedBirthProvince) {
        _this.birthCityList = await this.getCityList(
          val,
          _this.selectedBirthProvince.Id
        );
      }
    }, 400),
    fetchResCityList: debounce(async function(val) {
      let _this = this;
      if (_this.selectedProvince) {
        _this.cityList = await this.getCityList(val, _this.selectedProvince.Id);
      }
    }, 400),
    async selectCity(city) {
      let _this = this;
      if (city.Id != null) {
        //vm.selectedCity = city;
        _this.user.ResCity = city.Nome;
        _this.resetAddress();
        this.user.ResIstatCode = await IperalCustomService.getIstatCode(
          this.selectedProvince.Istat,
          city.Id
        );
      } else if (this.cityList.length > 0) {
        _this.selectedCity = this.cityList[0];
        this.selectCity(_this.selectedCity);
      }
    },
    resetCity() {
      this.selectedCity = null;
      this.user.ResCity = null;
      this.cityList = [];
    },
    async getAddressList(address, cityId) {
      this.addressList = await IperalCustomService.getPosteAddressList(
        address,
        cityId
      );
    },
    fetchAddressSuggestion: debounce(function(val) {
      let _this = this;
      if (_this.selectedCity) {
        this.getAddressList(val, _this.selectedCity.Id);
      }
    }, 400),
    selectAddress(address) {
      if (address.Id) {
        this.user.ResAddress = address.Nome;
        this.postalcodeList = address.elencoCAP;
        if (address.elencoCAP.length == 1) {
          this.user.ResCAP = address.elencoCAP[0];
        }
      } else if (this.addressList.length > 0) {
        this.selectedAddress = this.addressList[0];
        this.selectAddress(this.addressList[0]);
      }
    },
    resetAddress() {
      let _this = this;
      _this.selectedAddress = null;
      _this.addressList = [];
      _this.user.ResAddress = null;
    },
    async fetchUserData() {
      let _this = this;
      _this.user = await IperalCustomService.getUser();
      _this.formatData();
    },
    async fetchDefaultBirthData() {
      let _this = this;
      if (_this.countryList.length == 0) {
        _this.countryList = await IperalCustomService.getCountyList();
      }
      var matchCountry = _this.user.BirthCountry
        ? _this.user.BirthCountry.toUpperCase()
        : "ITALIA";
      _this.selectedBirthCountry = _this.countryList.find(
        p => p.Name.toUpperCase() === matchCountry
      );
      if (_this.provinceList.length == 0) {
        _this.provinceList = await IperalCustomService.getPosteProvinceList();
      }
      if (_this.user.BirthProv) {
        _this.selectedBirthProvince = _this.provinceList.find(
          p => p.Sigla.toUpperCase() === _this.user.BirthProv.toUpperCase()
        );
        if (_this.selectedBirthProvince) {
          //vm.birthCity = _this.user.BirthCity;
          _this.birthCityList = await _this.getCityList(
            _this.user.BirthCity,
            _this.selectedBirthProvince.Id
          );
          _this.selectedBirthCity = _this.birthCityList.find(
            p => p.Nome.toUpperCase() === _this.user.BirthCity.toUpperCase()
          );
        }
      }
    },
    async fetchDefaultResData() {
      let _this = this;
      if (_this.countryList.length == 0) {
        _this.countryList = await IperalCustomService.getCountyList();
      }
      var matchCountry = _this.user.ResCountry
        ? _this.user.ResCountry.toUpperCase()
        : "ITALIA";
      _this.selectedCountry = _this.countryList.find(
        p => p.Name.toUpperCase() === matchCountry
      );
      if (_this.provinceList.length == 0) {
        _this.provinceList = await IperalCustomService.getPosteProvinceList();
      }
      if (_this.user.ResProv) {
        _this.selectedProvince = _this.provinceList.find(
          p => p.Sigla.toUpperCase() === _this.user.ResProv.toUpperCase()
        );
        if (_this.selectedProvince) {
          _this.cityList = await _this.getCityList(
            _this.user.ResCity,
            _this.selectedProvince.Id
          );
          _this.selectedCity = _this.cityList.find(
            p => p.Nome.toUpperCase() === _this.user.ResCity.toUpperCase()
          );
          //continua con l'indirizzo
          if (_this.selectedCity) {
            await _this.getAddressList(
              _this.user.ResAddress,
              _this.selectedCity.Id
            );
            _this.selectedAddress = _this.addressList.find(
              p => p.Nome.toUpperCase() === _this.user.ResAddress.toUpperCase()
            );
            _this.postalcodeList = _this?.selectedAddress?.elencoCAP;
          }
        }
      }
    },
    async generateFiscalCode() {
      let _this = this;
      // if (!_this.isUpdate) {
      _this.loadingFiscalCode = true;
      if (_this.isBirthInItaly) {
        _this.generateFiscalCodeByCity();
      }
      _this.loadingFiscalCode = false;
      // }
    },

    async generateFiscalCodeByCity() {
      let _this = this;
      if (
        _this.user.Name &&
        _this.user.Surname &&
        _this.user.BirthDate &&
        _this.user.Gender &&
        _this.selectedBirthProvince.Istat &&
        _this.selectedBirthCity.Id
      ) {
        // const res = await IperalCustomService.getFiscalCodeByCity(
        const fiscalCode = await IperalCustomService.getFiscalCode(
          _this.user.Name.trim(),
          _this.user.Surname.trim(),
          _this.$dayjs(_this.user.BirthDate).format("YYYY-MM-DD"),
          _this.user.Gender,
          _this.selectedBirthProvince.Istat,
          _this.selectedBirthCity.Id
        );
        if (fiscalCode) {
          Vue.set(this.user, "FiscalCode", fiscalCode);
        }
      }
    },
    async getPdvList() {
      const res = await IperalCustomService.getPDVList();
      this.pdvList = res;
    },
    async anonymizeAccount() {
      let confirm = await this.$dialog.confirm({
        text: global.vm.$t("message.anonymizeAccount")
      });
      if (confirm) {
        this.loadingAnonymizeAccount = true;
        let result = await UserService.anonymizeAccount();
        if (result.response.status === 0) {
          // se utente davvero anonimizzato -> logout
          this.logout();
          this.loadingAnonymizeAccount = false;
          this.$router.push({
            name: "Home",
            path: "/"
          });
        } else {
          this.loadingAnonymizeAccount = false;
        }
      }
    },
    changeHasCard(hasCard) {
      if (hasCard === "false") {
        this.user.CardCode = null;
      }
    }
  },
  watch: {
    birthCity(val, oldVal) {
      if (val && val.length > 0 && val != oldVal) {
        this.fetchBirthCityList(val);
      }
    },
    resCity(val, oldVal) {
      if (val && val.trim().length > 0 && val != oldVal) {
        this.fetchResCityList(val);
      }
    },
    resAddress(val, oldVal) {
      if (val && val.trim().length > 3 && val != oldVal) {
        this.fetchAddressSuggestion(val);
      }
    },
    birthDate(val, oldVal) {
      if (val && val.length > 0 && val != oldVal) {
        this.generateFiscalCodeByCity();
      }
    }
  },
  async created() {
    await this.fetchUserData();
    await this.fetchDefaultBirthData();
    if (this.isUpdate) {
      await this.fetchDefaultResData();
    }
    this.getPdvList();
  }
};
</script>
